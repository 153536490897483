export const COLORS = {
  MINT: '#52AF89',
  GREEN: '#679E63',
  DARK_BLUE: '#355382',
  LIGHT_BLUE: '#02539A',
  ORANGE: '#F6891A',
  BLACK: '#333333',
  LIGHT_BLACK: '#3E3E3E',
  LIGHT_GREY: '#777777',
  WHITE: '#FFFFFF',
};
