import Layout from '@/components/Layout';
import { COLORS } from '@/configs/color';

const ListBox = ({ title, body }) => (
  <Layout.ContextBox width="100%">
    <Layout.Div
      fontSize="2rem"
      fontWeight="700"
      color={COLORS.DARK_BLUE}
      margin="0 0 4px"
    >
      {title}
    </Layout.Div>
    <Layout.Div fontSize="1rem" fontWeight="400" lineHeight="1.8rem">
      {body}
    </Layout.Div>
  </Layout.ContextBox>
);

export default ListBox;
