import ComputerMan from '@/assets/computer_man.svg';
import ThirtyLabs from '@/assets/text/30labs.svg';
import WhoNeeds from '@/assets/text/who_needs.svg';
import Layout from '@/components/Layout';
import CustomerDesc from '@/components/View/CustomerDesc';
import { COLORS } from '@/configs/color';

const Section05 = () => {
  const Customers = [
    {
      up: '빠른 비지니스 검증이 필요한 번개형 고객님',
      down:
        '비지니스 아이디어를 한달 내에 어플리케이션으로 만들어 보고 싶어요.',
      marginBottom: '20px',
    },
    {
      up: '비용과 리스크를 최대한 줄이고 싶은 신중형 고객님',
      down: '500만원으로 퀄리티 있는 앱을 만들 수 있을까요?',
      marginBottom: '20px',
    },
    {
      up: '스타트업의 빠른 MVP가 필요하신 다급형 고객님',
      down:
        '한달내에 투자자에게 보여줄 프로토타입 어플리케이션 개발이 가능할까요?',
      marginBottom: '20px',
    },
    {
      up: '중견, 대기업에서 사내 프로젝트 개발 진행이 어려운 곤란형 고객님',
      down: '회사에서 진행하는 서비스 프로젝트 앱을 빠르게 만들 수 있을까요?',
    },
  ];

  return (
    <>
      <Layout.Div
        height="auto"
        background="#FAFAFA"
        boxShadow="inset 0 10px 30px rgba(0,0,0,0.04)"
      >
        <Layout.Wrap padding="100px 0" position="relative">
          <Layout.Div
            className="up"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Layout.Div
              background={`center / contain no-repeat url(${ThirtyLabs})`}
              width="140px"
              height="24px"
              mobile={{
                height: '18px',
              }}
            />
            <Layout.Div
              background={`center / contain no-repeat url(${WhoNeeds})`}
              width="100%"
              height="60px"
              margin="20px auto 0"
              mobile={{
                margin: '12px 0 0',
                'max-height': '28px',
                height: '28px',
              }}
            />
            <Layout.Div marginTop="40px">
              <Layout.Span
                fontSize="1.1rem"
                color={COLORS.LIGHT_GREY}
                fontWeight="medium"
                mobile={{
                  width: '100%',
                  height: 'auto',
                }}
              >
                30일 연구소는 이런 고객님들을 위해 일하고 싶습니다!
              </Layout.Span>
            </Layout.Div>
          </Layout.Div>
          <Layout.Div
            className="down"
            marginTop="100px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mobile={{
              width: '100%',
              'flex-wrap': 'wrap',
              'margin-top': '20px',
            }}
          >
            <Layout.Div className="left" mobile={{ order: 2 }}>
              {Customers.map((customer) => CustomerDesc(customer))}
            </Layout.Div>
            <Layout.Div
              className="right"
              background={`center / contain no-repeat url(${ComputerMan})`}
              width="604px"
              mobile={{ width: '100%', order: 1, height: '300px' }}
              height="452px"
            />
          </Layout.Div>
        </Layout.Wrap>
      </Layout.Div>
    </>
  );
};

export default Section05;
