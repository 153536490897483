import IMGIdeaPeople from '@/assets/idea_people.svg';
import LogoGray from '@/assets/logo_gray.svg';
import TextIdeaToLife from '@/assets/text/idea_to_life.svg';
import Layout from '@/components/Layout';
import View from '@/components/View';
import { COLORS } from '@/configs/color';

const Section02 = () => (
  <Layout.Div
    height="auto"
    background="#FAFAFA"
    boxShadow="inset 0 10px 30px rgba(0,0,0,0.04)"
  >
    <Layout.Wrap padding="100px 0" position="relative">
      {/* 내용 시작 */}
      <Layout.Div
        position="absolute"
        maxWidth="770px"
        width="100%"
        height="600px"
        background={`url(${IMGIdeaPeople}) right / contain no-repeat`}
        top="400px"
        right="0"
        zIndex="0"
        mobile={{
          top: '200px',
          opacity: 0.5,
        }}
      />
      <Layout.Div
        background={`url(${LogoGray}) center / contain no-repeat`}
        width="100%"
        height="24px"
        mobile={{
          height: '18px',
        }}
      />
      <Layout.Div
        background={`url(${TextIdeaToLife}) center / contain no-repeat`}
        width="100%"
        height="60px"
        margin="20px auto 0"
        mobile={{
          margin: '12px 0 0',
          'max-height': '28px',
          height: '28px',
        }}
      />
      <Layout.Div
        width="330px"
        textAlign="center"
        margin="40px auto 100px"
        fontSize="1.1rem"
        fontWeight="500"
        color="#777"
        mobile={{
          width: '100%',
          height: 'auto',
        }}
      >
        30일 연구소는 고객님의 비지니스 아이디어에 생명을 불어넣습니다.
      </Layout.Div>
      {/* Lean Startup & Iteration */}
      <Layout.Div
        width="590px"
        mobile={{ width: '100%' }}
        margin="0 0 96px"
        position="relative"
        zIndex="2"
      >
        <Layout.Div fontSize="1.4rem" fontWeight="700" color={COLORS.DARK_BLUE}>
          Lean Startup & Iteration
        </Layout.Div>
        <Layout.Div
          fontSize="1rem"
          fontWeight="500"
          color="#777"
          margin="20px 0"
        >
          30일 연구소는 고객님의 비지니스가 더 성공적일 수 있도록, 단계적으로
          검증해 나가면서 사용자들의 요구를 찾고 해결하는 방식을 고수합니다.
          이러한 방법론은 이미 Silicon Valley 와 국내의 많은 스타트업들이
          사용하고 있고 성공적인 결과물을 만들어가고 있습니다.
          <br />
          <br />
          <Layout.Span fontWeight="700">
            Lean Startup 방식은 크게 아래와 같이 3단계를 반복으로 이루어집니다.
          </Layout.Span>
        </Layout.Div>
        <View.ListBox
          title="01"
          body="최소의 핵심기능만을 담은 개발 (또는 개선, 조정된 기존 기능을 개발)"
        />
        <View.ListBox title="02" body="사용자들의 반응을 측정" />
        <View.ListBox
          title="03"
          body="측정된 데이터를 기반으로 사용자들의 사용 패턴 분석 & 강화할 수 있는 기능의 아이디어(시나리오) 구상 "
        />
      </Layout.Div>
      {/* 프로젝츠 최초 시작 시 프로세스 */}
      <Layout.Div width="100%">
        <Layout.Div
          fontSize="1.4rem"
          fontWeight="700"
          color={COLORS.DARK_BLUE}
          margin="0 0 20px"
        >
          프로젝츠 최초 시작 시 프로세스
        </Layout.Div>
        <View.ListBox
          title={
            <>
              01{' '}
              <Layout.Span position="relative" fontSize="1.7rem">
                컨설팅
              </Layout.Span>
            </>
          }
          body={
            <>
              - 고객님께서 해결(구현)하고자 요구 분석을 정리합니다.
              <br />- Lean Startup의 방식을 기반으로 고객님의 비지니스를 위한
              개발을 초기 MVP(Minimum Viable Product) 를 설정합니다.{' '}
            </>
          }
        />
        <View.ListBox
          title={
            <>
              02{' '}
              <Layout.Span position="relative" fontSize="1.7rem">
                설계 및 기획
              </Layout.Span>
            </>
          }
          body={
            <>
              - 컨설팅에서 설정된 핵심 주요기능의 설계를 위한 UI / UX 리서치가
              진행됩니다.
              <br />- 타겟 사용자에 시나리오에 근거한 UX 스토리보드 및 기능명세
              기획이 진행됩니다.
            </>
          }
        />
        <View.ListBox
          title={
            <>
              03{' '}
              <Layout.Span position="relative" fontSize="1.7rem">
                개발
              </Layout.Span>
            </>
          }
          body={
            <>
              - 안정되고 고도화된 플렛폼과 프레임 워크로 고객님의 모바일
              어플리케이션을 제작합니다.
              <br />- 수년간의 경험과 실력 그리고 노하우를 가진 네이티브 모바일
              앱 개발자 역량으로 더 안정적이고 사용자 친화적인 개발을
              추구합니다. <br />- 고객님의 비지니스를 좀 더 효과적이고 확장성을
              극대화 하도록 인증된 크로스 플랫폼을 사용합니다. <br />- 추후 추가
              개발을 위한 코드 버전관리 및 API 문서작성등을 효율적이고
              효과적으로 진행합니다.
            </>
          }
        />
        <View.ListBox
          title={
            <>
              04{' '}
              <Layout.Span position="relative" fontSize="1.7rem">
                분석
              </Layout.Span>
            </>
          }
          body={
            <>
              - 처음 단계에서 의도와 목표되었던 최소 핵심 기능에 대한 데이터
              분석이 이루어집니다. <br />- 추후 필요한 보완적인 또는 좀 더
              개선적인 기능에 대한 컨설팅이 진행됩니다.
            </>
          }
        />
      </Layout.Div>
      {/* 내용 끝 */}
    </Layout.Wrap>
  </Layout.Div>
);

export default Section02;
