import LogoBottom from '@/assets/logo_bottom.svg';
import Layout from '@/components/Layout';
import { COLORS } from '@/configs/color';

const Bottom = () => {
  return (
    <Layout.Div
      background={COLORS.BLACK}
      width={'100%'}
      height={'360px'}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      mobile={{
        'flex-direction': 'column',
        height: '240px',
        'padding-bottom': '40px',
      }}
    >
      <Layout.Div
        className="left"
        display={'flex'}
        alignItems={'center'}
        padding={'0 490px 0 0'}
        height={'274px'}
        borderRight={`1.6px solid ${COLORS.LIGHT_GREY}`}
        mobile={{
          padding: '0',
          height: '240px',
          borderRight: `0`,
        }}
      >
        <Layout.Div
          width={'234px'}
          height={'94px'}
          background={`center / contain no-repeat url(${LogoBottom})`}
          mobile={{
            width: '117px',
            height: '47px',
          }}
        />
      </Layout.Div>
      <Layout.Div
        className="right"
        margin={'0 0 0 34px'}
        color={COLORS.WHITE}
        fontSize={'16px'}
        fontWeight={'bold'}
        mobile={{
          margin: 0,
          fontSize: '0.9rem',
        }}
      >
        <Layout.Span display="block" margin={'0 0 9px 0'}>
          문의사항
        </Layout.Span>
        <Layout.Span
          display="block"
          fontSize={'32px'}
          margin={'0 0 4px 0'}
          mobile={{
            fontSize: '24.6px',
          }}
        >
          010.6799.1866
        </Layout.Span>
        <Layout.Span display="block" margin={'0 0 16px 0'}>
          thirtydays.lab@gmail.com
        </Layout.Span>
        <Layout.Div
          display="block"
          width="100%"
          mobile={{
            width: 'auto',
          }}
        >
          서울특별시 강남구 테헤란로 419, 17층 1728호
        </Layout.Div>
      </Layout.Div>
    </Layout.Div>
  );
};

export default Bottom;
