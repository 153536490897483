import { COLORS } from '@/configs/color';

import Layout from '../Layout';

const TechSpecForm = ({
  color,
  marginTop,
  thumbnail,
  title,
  subtitle,
  desc,
  isMobile,
}) => (
  <Layout.ContextBox
    width="590px"
    display="flex"
    flexShrink="0"
    flexDirection="row"
    border={`2.4px solid ${color}`}
    borderRadius="12px"
    marginBottom="20px"
    background={COLORS.WHITE}
  >
    <Layout.Div
      className="left"
      width="112px"
      height="112px"
      background={`center / contain no-repeat url(${thumbnail})`}
      marginRight="16px"
      marginTop={marginTop}
    />
    <Layout.Div className="right" width="400px">
      <Layout.Span
        display="block"
        fontSize={`${isMobile ? '30px' : '32px'}`}
        fontWeight="bold"
        marginBottom="12px"
        color={color}
      >
        {title}
      </Layout.Span>
      <Layout.Div lineHeight={`${isMobile ? '1.5rem' : ''}`}>
        {subtitle && (
          <Layout.Span
            display="block"
            fontSize="1rem"
            fontWeight="bold"
            color={COLORS.LIGHT_GREY}
            mobile={{
              'font-size': '0.9rem',
            }}
          >
            {subtitle}
          </Layout.Span>
        )}
        {desc.map((paragraph, index) =>
          paragraph === '' ? (
            <Layout.Span
              key={index}
              display="block"
              fontSize="1.1rem"
              fontWeight="medium"
              color={COLORS.LIGHT_GREY}
            >
              &nbsp;
            </Layout.Span>
          ) : (
            <Layout.Span
              key={index}
              display="block"
              fontSize={`${(isMobile) => (isMobile ? '0.8rem' : '1.1rem')}`}
              fontWeight="medium"
              color={COLORS.LIGHT_GREY}
              lineHeight="1.6rem"
            >
              {paragraph}
            </Layout.Span>
          ),
        )}
      </Layout.Div>
    </Layout.Div>
  </Layout.ContextBox>
);

export default TechSpecForm;
