import { useWindowResize } from 'beautiful-react-hooks';
import { useState } from 'react';

const WindowSizeReporter = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const onWindowResize = useWindowResize();

  onWindowResize(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  return {
    device: width >= 900 ? 'pc' : 'mobile',
    size: {
      width,
      height,
    },
  };
};

export default WindowSizeReporter;
