import LogoSmall from '@/assets/logo_small.svg';
import ThirtyLabs from '@/assets/text/30labs.svg';
import ContactUs from '@/assets/text/contact_us.svg';
import Layout from '@/components/Layout';
import LinkButton from '@/components/View/LinkButton';
import { COLORS } from '@/configs/color';
import WindowSizeReporter from '@/hooks/WindowSizeReporter';

const Section08 = () => {
  const { device } = WindowSizeReporter();

  return (
    <Layout.Div
      background="#FAFAFA"
      boxShadow="inset 0 10px 30px rgba(0,0,0,0.04)"
      paddingBottom="100px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      mobile={{
        padding: '0 28px 100px 28px',
      }}
    >
      <Layout.Div
        background={`center / contain no-repeat url(${ThirtyLabs})`}
        width="140px"
        height="24px"
        marginTop="100px"
        mobile={{
          width: '105px',
          height: '18px',
        }}
      />
      <Layout.Div
        background={`center / contain no-repeat url(${ContactUs})`}
        width="440px"
        height="56px"
        marginTop="20px"
        mobile={{
          width: '220px',
          height: '28px',
          'margin-top': '12px',
        }}
      />
      <Layout.Div
        marginTop="40px"
        mobile={{
          'text-align': 'center',
        }}
      >
        <Layout.Span
          fontSize="18px"
          color={COLORS.LIGHT_GREY}
          fontWeight="medium"
          mobile={{
            'font-size': '1.1rem',
          }}
        >
          30일 연구소와 만나보세요
        </Layout.Span>
      </Layout.Div>
      <Layout.ContextBox
        width="590px"
        height="330px"
        marginTop="100px"
        border={`2.4px solid ${COLORS.MINT}`}
        borderRadius="12px"
        mobile={{
          width: '100%',
          height: '226px',
          'margin-top': '60px',
        }}
      >
        <Layout.Div
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop="16px"
          mobile={{
            'margin-top': '0',
          }}
        >
          <Layout.Div
            fontWeight="bold"
            color={COLORS.MINT}
            marginLeft="16px"
            mobile={{
              'margin-left': '0',
            }}
          >
            <Layout.Span
              display="block"
              fontSize="24px"
              margin="0 0 4px 0"
              mobile={{
                'font-size': '18px',
              }}
            >
              연구소장
            </Layout.Span>
            <Layout.Span
              display="block"
              fontSize="40px"
              position="relative"
              top="-10px"
              mobile={{
                'font-size': '32px',
              }}
            >
              지정의
            </Layout.Span>
          </Layout.Div>
          <Layout.Div
            width="68px"
            height="56px"
            background={`center / contain no-repeat url(${LogoSmall})`}
            marginRight="16px"
            mobile={{
              width: '34px',
              height: '28px',
              'margin-right': '0',
            }}
          />
        </Layout.Div>
        <Layout.Div
          fontSize="24px"
          color={COLORS.LIGHT_GREY}
          marginLeft="16px"
          mobile={{
            'font-size': '18px',
            'margin-left': '0',
          }}
        >
          <Layout.Span display="block">thirtydays.lab@gmail.com</Layout.Span>
          <Layout.Span
            display="block"
            fontWeight="bold"
            mobile={{
              'margin-top': '4px',
            }}
          >
            010.6799.1866
          </Layout.Span>
        </Layout.Div>
        <Layout.Div
          display="flex"
          justifyContent="flex-end"
          marginRight="16px"
          marginTop="56px"
          mobile={{
            'margin-top': '28px',
            'margin-right': '0',
          }}
        >
          <LinkButton
            title="의뢰서 보내기"
            onClick={() => window.open('https://forms.gle/8Pxk8mFrjcLG3xX39')}
            width="194px"
            height="40px"
            isMobile={device === 'mobile'}
            mobile={{
              width: '90px',
              height: '30px',
            }}
          />
        </Layout.Div>
      </Layout.ContextBox>
    </Layout.Div>
  );
};

export default Section08;
