import { darken, lighten } from 'polished';
import styled from 'styled-components';

const Button = styled.button`
  ${({ display }) => display && `display : ${display}`};
  ${({ width }) => width && `width : ${width}`};
  ${({ height }) => height && `height : ${height}`};
  ${({ margin }) => margin && `margin : ${margin}`};
  ${({ padding }) => padding && `padding : ${padding}`};
  ${({ background }) => background && `background : ${background}`};
  ${({ color }) => color && `color : ${color}`};
  ${({ position }) => position && `position : ${position}`};
  ${({ border }) => border && `border : ${border}`};
  ${({ top }) => top && `top : ${top}`};
  ${({ left }) => left && `left : ${left}`};
  ${({ bottom }) => bottom && `bottom : ${bottom}`};
  ${({ right }) => right && `right : ${right}`};
  ${({ animation }) => animation && `animation : ${animation}`};
  ${({ minHeight }) => minHeight && `min-height : ${minHeight}`};
  ${({ alignContent }) => alignContent && `align-content : ${alignContent}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content : ${justifyContent}`};
  ${({ flexDirection }) =>
    flexDirection && `flex-direction : ${flexDirection}`};
  ${({ flexWrap }) => flexWrap && `flex-wrap : ${flexWrap}`};
  ${({ flexShrink }) => flexShrink && `flex-shrink : ${flexShrink}`};
  ${({ alignItems }) => alignItems && `align-items : ${alignItems}`};
  ${({ fontSize }) => fontSize && `font-size : ${fontSize}`};
  ${({ fontWeight }) => fontWeight && `font-weight : ${fontWeight}`};
  ${({ borderRadius }) => borderRadius && `border-radius : ${borderRadius}`};
  ${({ zIndex }) => zIndex && `z-index : ${zIndex}`};
  ${({ letterSpacing }) =>
    letterSpacing && `letter-spacing : ${letterSpacing}`};
  ${({ lineHeight }) => lineHeight && `line-height : ${lineHeight}`};
  transition: 0.25s;

  &:hover {
    background: ${({ background }) =>
      background ? lighten(0.1, background) : 'inherit'};
    transition: 0.25s;
  }
  &:active {
    background: ${({ background }) =>
      background ? darken(0.1, background) : 'inherit'};
    transition: 0.25s;
  }

  @media screen and (max-width: 900px) {
    ${({ mobile }) => ({ ...mobile })};
  }
`;

export default Button;
