import LogoGray from '@/assets/logo_gray.svg';
import TextAboutUs from '@/assets/text/about_us.svg';
import Layout from '@/components/Layout';
import View from '@/components/View';

const Section02 = () => (
  <Layout.Div height="auto" background="#FAFAFA">
    <Layout.Wrap
      padding="100px 0"
      mobile={{
        padding: '40px 0',
      }}
    >
      {/* 내용 시작 */}
      <Layout.Div
        background={`url(${LogoGray}) left / contain no-repeat`}
        width="100%"
        height="24px"
        mobile={{
          height: '18px',
        }}
      />
      <Layout.Div
        background={`url(${TextAboutUs}) left / contain no-repeat`}
        width="100%"
        height="131px"
        margin="20px 0 0"
        mobile={{
          margin: '12px 0 0',
          'max-height': '60px',
          height: '60px',
        }}
      />
      <Layout.Div
        display="flex"
        width="100%"
        margin="80px 0 0"
        height="600px"
        flexDirection="column"
        flexWrap="wrap"
        alignContent="space-between"
        mobile={{
          height: 'auto',
        }}
      >
        <View.QABox
          question="30일 연구소가 뭔가요?"
          answer="30일 연구소는 최소한의 핵심기능이 들어간 모바일 앱(Minimum Viable Product, MVP)을 제작을 합니다.
퀄리티 있는 모바일 앱을 기획부터 배포까지 30일내에 진행되기 때문에 30일 연구소 입니다."
        />
        <View.QABox
          question="프로젝트의 범위는 어떻게 되나요?"
          answer="30일 연구소에서는 이름처럼 30일 동안의 개발할 수 있는 핵심기능이 담긴 모바일 앱을 네이티브로 제작합니
다. 30일동안 개발 된 앱이 배포가 되서 사용자들의 일정 데이터가 확보되고 유의미한 분석결과가 나오면, 추후에
또 다른 30일 동안 보완 또는 개선할 기능을 추가/수정하게 됩니다. 이러한 방법론을 Lean Startup이라고 하며,
30일 연구소는 고객님의 비지니스를 위해서 이 방법론을 고수합니다. "
        />
        <View.QABox
          question="그럼 30일 연구소는 그냥 개발회사 인가요? "
          answer="소프트웨어를 개발하기 때문에 간단한 답변으로는 개발회사가 맞습니다. 하지만 30일 연구소는 연구소라는
이름을 가진 것처럼 고객님의 비지니스를 위한 리서치가 진행되고, Lean Startup의 방법으로 개발하며 고객님의
소중한 비지니스가 검증, 보완, 개선 그리고 확신을 가질 수 있도록 도와드립니다. "
        />
        <View.QABox
          question="30일 연구소에는 어떤 분들이 일하시나요?"
          answer="연구소에는 무엇보다도 미디어, 컨텐츠, 커뮤니케이션 등의 분야에서 많은 지식과 경험을 가진 맴버로 구성되
어 있습니다. 스타트업의 설립부터 EXIT을 경험하면서 배운 많은 다양한 실무적인 역량을 갖추고 있습니다. "
        />
      </Layout.Div>
      {/* 내용 끝 */}
    </Layout.Wrap>
  </Layout.Div>
);

export default Section02;
