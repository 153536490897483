import Layout from '../Layout';

const ColorBox = ({ title, body, color, background }) => (
  <Layout.ContextBox background={background} color={color || 'white'}>
    <Layout.Div fontWeight="700" fontSize="1.8rem" margin="0 0 12px">
      {title}
    </Layout.Div>
    <Layout.Div lineHeight="1.5rem">{body}</Layout.Div>
  </Layout.ContextBox>
);

export default ColorBox;
