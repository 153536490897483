import styled from 'styled-components';

import { COLORS } from '@/configs/color';

import Layout from '../Layout';

const AnchButton = styled.a`
  color: #ffffff;
  text-decoration: none;
  ${({ width }) => width && `width : ${width}`};
  ${({ height }) => height && `height : ${height}`};
  margin-rigth: '36px';

  display: flex;
  justify-content: end;
  align-items: center;

  &:hover {
    color: #ffffff;
  }
  &:active {
    color: #ffffff;
  }

  @media screen and (max-width: 900px) {
    ${({ mobile }) => ({ ...mobile })};
  }
`;

const LinkButton = ({
  title,
  to,
  width,
  height,
  isMobile,
  mobile,
  onClick,
}) => (
  <AnchButton
    href={to}
    width={width}
    height={height}
    mobile={mobile}
    onClick={onClick}
  >
    <Layout.Div
      width={width}
      height={height}
      background={COLORS.MINT}
      color={COLORS.WHITE}
      fontSize={`${isMobile ? '12px' : '16px'}`}
      fontWeight="bold"
      borderRadius="6px"
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mobile={mobile}
    >
      <Layout.Div>{title}</Layout.Div>
    </Layout.Div>
  </AnchButton>
);

export default LinkButton;
