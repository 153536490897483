import DotBlue from '@/assets/dot_blue.svg';
import DotGreen from '@/assets/dot_green.svg';
import DotGrey from '@/assets/dot_grey.svg';
import { COLORS } from '@/configs/color';

import Layout from '../Layout';

const PriceBox = ({ title, subtitle, color, price, desc, isMobile }) => {
  let dot = '';
  switch (title) {
    case '스탠다드': {
      dot = DotBlue;
      break;
    }
    case '프리미엄': {
      dot = DotGreen;
      break;
    }
    default:
      dot = DotGrey;
  }

  return (
    <Layout.ContextBox
      background={COLORS.WHITE}
      width="385px"
      height="484px"
      display="flex"
      flexDirection="column"
      borderRadius="12px"
      border={`${title === '프리미엄' ? `2.4px solid ${color}` : ''}`}
      mobile={{
        width: '100%',
        height: 'auto',
      }}
      padding="40px 0"
    >
      <Layout.Div display="flex" flexDirection="column" alignItems="center">
        <Layout.Div
          fontSize="2rem"
          fontWeight="bold"
          color={color}
          display="block"
        >
          {title}
        </Layout.Div>
        <Layout.Div
          fontSize="16px"
          fontWeight="medium"
          color={COLORS.LIGHT_GREY}
          display="block"
          marginTop="4px"
        >
          {subtitle}
        </Layout.Div>
        <Layout.Div
          background="#FAFAFA"
          width="100%"
          textAlign="center"
          fontWeight="bold"
          color={color}
          marginTop="20px"
          marginBottom="40px"
          paddingTop="26px"
          paddingBottom="26px"
          paddingLeft="-20px"
          paddingRight="-20px"
          fontSize="2.2rem"
        >
          <Layout.Span marginRight="8px">{`${price[0]} ~ ${price[1]}`}</Layout.Span>
          <Layout.Span fontSize={`${isMobile ? '18px' : '20px'}`}>
            만원
          </Layout.Span>
        </Layout.Div>
      </Layout.Div>
      <Layout.Div
        display="flex"
        flexDirection="column"
        alignItems="left"
        marginLeft="32px"
        mobile={{
          'font-size': '1.1rem',
        }}
      >
        {desc.map((description, index) => (
          <Layout.Div
            key={index}
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom="10px"
          >
            <Layout.Div
              background={`center / contain no-repeat url(${dot})`}
              width="8px"
              height="8px"
              marginRight="12px"
            />
            <Layout.Div color={COLORS.LIGHT_GREY} fontWeight="medium">
              {description}
            </Layout.Div>
          </Layout.Div>
        ))}
      </Layout.Div>
    </Layout.ContextBox>
  );
};

export default PriceBox;
