import { COLORS } from '@/configs/color';

import Layout from '../Layout';

const CustomerDesc = ({ up, down, marginBottom }) => (
  <Layout.Div
    width="580px"
    mobile={{ width: '100%' }}
    marginBottom={marginBottom}
  >
    <Layout.Span
      display="block"
      fontSize="1.4rem"
      fontWeight="bold"
      color={COLORS.DARK_BLUE}
      marginBottom="8px"
      mobile={{ width: '100%', 'font-size': '1.2rem' }}
    >
      {up}
    </Layout.Span>
    <Layout.Span
      fontSize="1.1rem"
      fontWeight="medium"
      color={COLORS.BLACK}
      mobile={{ 'font-size': '1rem' }}
    >
      {down}
    </Layout.Span>
  </Layout.Div>
);

export default CustomerDesc;
