import styled from 'styled-components';

import Div from './Div';

const Wrap = styled(Div)`
  width: ${({ width }) => width || '1200px'};
  margin: ${({ margin }) => margin || '0 auto'};
  justify-content: ${({ justifyContent }) => justifyContent || 'auto'};
  z-index: ${({ zIndex }) => zIndex || 'auto'};

  @media screen and (max-width: 900px) {
    width: auto;
    margin: 0 28px;
    ${({ mobile }) => ({ ...mobile })};
  }
`;

export default Wrap;
