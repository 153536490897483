import styled from 'styled-components';

import Div from './Div';

const ContextBox = styled(Div)`
  width: ${({ width }) => width || '590px'};
  background: ${({ background }) => background || 'white'};
  padding: ${({ padding }) => (padding ? '' : '20px')};
  box-sizing: border-box;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: ${({ marginTop }) => (marginTop ? '' : '0 0 20px')};
  transition: 0.25s;
  cursor: help;

  &:hover {
    box-shadow: 0 16px 30px rgba(0, 0, 0, 0.2);
    transition: 0.25s;
  }
  &:active {
    padding: 28px;
    transition: 0.25s;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export default ContextBox;
