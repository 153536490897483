import AWS from '@/assets/logo_aws.svg';
import FireBase from '@/assets/logo_firebase.svg';
import Flutter from '@/assets/logo_flutter.svg';
import NodeJS from '@/assets/logo_nodeJS.svg';
import ThirtyLabs from '@/assets/text/30labs.svg';
import TechStack from '@/assets/text/tech_stack.svg';
import Layout from '@/components/Layout';
import TechSpecForm from '@/components/View/TechSpec.js';
import { COLORS } from '@/configs/color';

const Section06 = () => {
  const TechSpecs = [
    {
      color: COLORS.LIGHT_BLUE,
      marginTop: '8px',
      thumbnail: Flutter,
      title: 'Flutter',
      subtitle: '2020 사랑받은 앱개발 프레임워크 Flutter',
      desc: [
        'Flutter는 2019, 2020년도에 전세계적으로 가장 사랑받은 모바일 앱 개발 프레임워크 입니다. 현재 국내에서도 점차 점유율이 올라가는 추세이며, 앞으로도 많은 비지니스들이 이 기술을 사용하고 주목하고 있습니다.',
        '',
        'Flutter는 많은 장점들을 가지고 있습니다. 그 중에서도 가장 사랑받는 이유는 바로 한가지 언어로 iOS와 안드로이드 네이티브 앱이 개발된다는 점입니다. 네이티브로 만들어지면, 더욱 스마트폰에 최적화 된 성능과 사용자 친화적인 UI / UX를 가질 수 있습니다. 이 부분은 iOS와  안드로이드 앱을 따로 개발했을 때 가질수 있는 부분이었지만, Flutter가 획기적으로 해결하고 보완했습니다.',
        '',
        '이외에도 많은 장점들을 가지고 있습니다.',
        '',
        '-  iOS와 안드로이드에 적용할 수 있는 동일한 UI와 비지니스 로직',
        '- 단일화 되고 최소화되는 개발 코드',
        '- 빠른 시장진입 스피드',
        '- 모바일 앱 뿐만 아니라 다른 플랫폼의 확장성(웹, 윈도우즈, MacOS)',
      ],
    },
    {
      color: COLORS.GREEN,
      thumbnail: NodeJS,
      title: 'Node.js',
      desc: [
        'Node.js는 의심할 여지가 없는 서버 프레임워크 입니다. 현재 전세계에서 제일 사랑받는 프레임워크이며, 특히 스타트업이 사용하기 좋게 가볍고 확장성이 뛰어나다는 장점을 가지고 있습니다. ',
        '',
        '고객님의 비지니스 단계와 사용자의 증가량에 맞춰서 최적의 서버의 용량구성과 퍼포먼스를 적절하고 효울적이게 관리할 수 있습니다.',
      ],
    },
    {
      color: COLORS.ORANGE,
      marginTop: '10px',
      thumbnail: FireBase,
      title: 'Firebase',
      desc: [
        'Firebase는 모바일 앱을 개발하는데 없어서는 안되는 툴입니다.',
        'Google Analytics, Firebase Cloud Message, Realtime Database, Authentication등의 기능들이 앱의 기능들을 더 풍요롭고 편리하게 만들어줍니다.',
      ],
    },
    {
      color: COLORS.LIGHT_BLACK,
      thumbnail: AWS,
      title: 'Amazon Web Services',
      desc: [
        '30일 연구소는 Amazon Web Service를 다년간 적극적으로 활용한 개발자로 구성되어 있습니다. 고객님의 서버를 저렴하지만 더 효율적이고 확정성있게 관리할 수 있으며 무엇보다도 안전하고 관리 할 수 있습니다.',
      ],
    },
  ];

  return (
    <>
      <Layout.Div
        height="auto"
        background="#FAFAFA"
        boxShadow="inset 0 10px 30px rgba(0,0,0,0.04)"
      >
        <Layout.Wrap padding="100px 0" position="relative">
          <Layout.Div
            className="up"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Layout.Div
              background={`center / contain no-repeat url(${ThirtyLabs})`}
              width="140px"
              height="24px"
              mobile={{
                height: '18px',
              }}
            />
            <Layout.Div
              background={`center / contain no-repeat url(${TechStack})`}
              width="386px"
              height="56px"
              marginTop="20px"
              mobile={{
                width: '100%',
                margin: '12px 0 0',
                'max-height': '28px',
                height: '28px',
              }}
            />
            <Layout.Div marginTop="40px">
              <Layout.Span
                fontSize="1.1rem"
                color={COLORS.LIGHT_GREY}
                fontWeight="medium"
              >
                고객님의 비지니스를 위해 최적화 된 기술을 사용합니다.
              </Layout.Span>
            </Layout.Div>
          </Layout.Div>
          <Layout.Div
            className="down"
            marginTop="100px"
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            alignContent="space-between"
            width="1200px"
            height="900px"
            mobile={{
              width: '100%',
              height: 'auto',
              'flex-wrap': 'wrap',
              'margin-top': '60px',
            }}
          >
            {TechSpecs.map((techspec) => TechSpecForm(techspec))}
          </Layout.Div>
        </Layout.Wrap>
      </Layout.Div>
    </>
  );
};

export default Section06;
