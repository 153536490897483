import ICON_A from 'assets/icons/icon_a.svg';
import ICON_Q from 'assets/icons/icon_q.svg';

import { COLORS } from '@/configs/color';

import Layout from '../Layout';

const QABox = ({ question, answer }) => (
  <Layout.ContextBox>
    <Layout.Div
      display="flex"
      alignItems="center"
      color={COLORS.DARK_BLUE}
      fontWeight="500"
      fontSize="1.1rem"
    >
      <Layout.Div
        width="20px"
        height="24px"
        display="inline-block"
        flexShrink="0"
        background={`url(${ICON_Q}) left / cover no-repeat`}
        margin="0 12px 0 0"
      />
      {question}
    </Layout.Div>
    <Layout.Div
      margin="14px 0 10px"
      width="100%"
      height="2px"
      background="#EEEEEE"
    />
    <Layout.Div display="flex" lineHeight="2rem">
      <Layout.Div
        width="20px"
        height="24px"
        flexShrink="0"
        background={`url(${ICON_A}) top / contain no-repeat`}
        margin="4px 12px 0 0"
      />
      {answer}
    </Layout.Div>
  </Layout.ContextBox>
);

export default QABox;
