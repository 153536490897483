import LogoBig from '@/assets/logo_big.svg';
import LogoHeader from '@/assets/logo_header.svg';
import TitleMain from '@/assets/text/title_main.svg';
import Layout from '@/components/Layout';
import { COLORS } from '@/configs/color';

const Section01 = () => (
  <Layout.Div
    height="100vh"
    minHeight="860px"
    background="#FAFAFA"
    mobile={{
      'min-height': '700px',
      height: '100vh',
    }}
  >
    <Layout.Div
      position="absolute"
      top="0px"
      width="100%"
      height="60%"
      background="white"
      zIndex={1}
    />
    <Layout.Wrap
      padding="60px 0 0"
      height="calc(100vh - 120px)"
      minHeight="800px"
      position="relative"
      zIndex={2}
      mobile={{
        padding: '40px 0 0',
        height: 'calc(100vh - 80px)',
        'min-height': '600px',
      }}
    >
      {/* 내용 시작 */}
      <Layout.Wrap
        position="absolute"
        height="calc(100vh - 60px)"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        mobile={{
          width: '100%',
          left: '-28px',
        }}
      >
        <Layout.Div
          width="100%"
          display="flex"
          justifyContent="space-between"
          zIndex={2}
        >
          <Layout.Div
            background={`url(${LogoHeader}) left / contain no-repeat`}
            width="149px"
            height="60px"
            mobile={{
              height: '40px',
            }}
          />
          <Layout.Button
            borderRadius="5px"
            display="flex"
            width="160px"
            height="40px"
            justifyContent="center"
            alignItems="center"
            background={COLORS.MINT}
            color="white"
            fontWeight="bold"
            fontSize="1rem"
            mobile={{
              width: '100px',
            }}
            onClick={() => window.open('https://forms.gle/8Pxk8mFrjcLG3xX39')}
          >
            문의하기
          </Layout.Button>
        </Layout.Div>
      </Layout.Wrap>
      <Layout.Div
        width="100%"
        position="relative"
        top="0px"
        height="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mobile={{
          width: 'auto',
          'flex-direction': 'column',
          'justify-content': 'center',
          height: 'calc(100vh - 80px)',
          'min-height': '700px',
        }}
      >
        <Layout.Div
          mobile={{
            order: 2,
            width: '100%',
          }}
        >
          <Layout.Div
            width="553px"
            height="135px"
            background={`url(${TitleMain}) center / contain no-repeat`}
            mobile={{
              width: '100%',
              height: '80px',
              'background-position': 'left',
              'background-size': 'contain',
            }}
          />
          <Layout.Div margin="40px 0 0 0" fontSize="1.1rem">
            <Layout.Span fontWeight="bold" display="block">
              30일 연구소는&nbsp;
            </Layout.Span>
            네이티브 모바일앱(iOS, Android)을 가장 빠르게, 가장 잘 만드는
            회사입니다.
          </Layout.Div>
        </Layout.Div>
        <Layout.Div
          width="624px"
          height="514px"
          background={`url(${LogoBig}) center / cover no-repeat`}
          animation="bounce 2s infinite alternate"
          mobile={{
            order: 1,
            'background-size': 'contain',
            'background-position': 'left',
            'margin-bottom': '40px',
            width: '100%',
            height: '200px',
          }}
        />
      </Layout.Div>
      {/* 내용 끝 */}
    </Layout.Wrap>
  </Layout.Div>
);

export default Section01;
