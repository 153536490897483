import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html{
    font-size:16px;
  }
  body {
    margin: 0;
    font-family: 'Lato', -apple-system,  'Noto Sans KR', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    

  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  button{
    border:0;
    outline:none;
    cursor:pointer;
  }

  button:active,
  button:focus{
    outline:none;
    cursor:pointer;
  }
  
  button span{
      position: relative;
  }


  @media screen and (max-width: 900px) {
    html{
      font-size: 14px;
    }
  }
`;

export default GlobalStyle;
