import ThirtyLabs from '@/assets/text/30labs.svg';
import Price from '@/assets/text/price.svg';
import Layout from '@/components/Layout';
import PriceBox from '@/components/View/PriceBox';
import { COLORS } from '@/configs/color';

const Section07 = () => {
  const Prices = [
    {
      title: '스탠다드',
      subtitle: '일반적인 서비스 기능 개발',
      color: COLORS.DARK_BLUE,
      price: [500, 1000],
      desc: [
        '아이디어 구체화가 필요함',
        '일반적인 서비스 기능',
        '베이직한 형태의 디자인',
        '제작 기간 한달 이내',
      ],
    },
    {
      title: '프리미엄',
      subtitle: '특수한 형태의 기능 개발',
      color: COLORS.MINT,
      price: [1000, 2000],
      desc: [
        '개발가능 수준으로 정리되어 있음',
        '특수한 형태의 기능 개발',
        '별도의 디자인 작업',
        '제작 기간 한달 이내',
      ],
    },
    {
      title: '데모',
      subtitle: '시연용 기능 개발',
      color: COLORS.LIGHT_GREY,
      price: [200, 500],
      desc: [
        '구체적인 데모를 보일 기능의 구체화',
        '구체적인 데모 기능',
        '베이직한 형태의 디자인',
        '10일 내',
      ],
    },
  ];

  return (
    <>
      <Layout.Div
        height="auto"
        background="#FAFAFA"
        boxShadow="inset 0 10px 30px rgba(0,0,0,0.04)"
      >
        <Layout.Wrap padding="100px 0" position="relative">
          <Layout.Div
            background={`left / contain no-repeat url(${ThirtyLabs})`}
            width="100%"
            height="24px"
            mobile={{
              height: '18px',
            }}
          />
          <Layout.Div
            background={`left / contain no-repeat url(${Price})`}
            width="100%"
            height="56px"
            marginTop="20px"
            mobile={{
              margin: '12px 0 0',
              'max-height': '28px',
              height: '28px',
            }}
          />
          <Layout.Div
            marginTop="40px"
            fontSize="1.1rem"
            color={COLORS.LIGHT_GREY}
            fontWeight="medium"
          >
            <Layout.Span display="block">
              퀄리티 있는 네이티브 iOS & 안드로이드 MVP를
            </Layout.Span>
            <Layout.Span display="block">
              30일 내에 500 ~ 1000만원대로 만들 수 있습니다.
            </Layout.Span>
          </Layout.Div>
          <Layout.Div
            className="down"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            flexDirection="row"
            marginTop="100px"
            marginBottom="100px"
            width="100%"
            mobile={{
              'margin-top': '60px',
              'margin-bottom': '0px',
            }}
          >
            {Prices.map((price) => PriceBox(price))}
          </Layout.Div>
        </Layout.Wrap>
      </Layout.Div>
    </>
  );
};

export default Section07;
