import styled from 'styled-components';

const Div = styled.div`
  ${({ display }) => display && `display : ${display}`};
  ${({ width }) => width && `width : ${width}`};
  ${({ height }) => height && `height : ${height}`};
  ${({ margin }) => margin && `margin : ${margin}`};
  ${({ marginTop }) => marginTop && `margin-top : ${marginTop}`};
  ${({ marginLeft }) => marginLeft && `margin-left : ${marginLeft}`};
  ${({ marginRight }) => marginRight && `margin-right : ${marginRight}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom : ${marginBottom}`};
  ${({ padding }) => padding && `padding : ${padding}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left : ${paddingLeft}`};
  ${({ paddingRight }) => paddingRight && `padding-right : ${paddingRight}`};
  ${({ paddingBottom }) =>
    paddingBottom && `padding-bottom : ${paddingBottom}`};
  ${({ paddingTop }) => paddingTop && `padding-top : ${paddingTop}`};
  ${({ background }) => background && `background : ${background}`};
  ${({ color }) => color && `color : ${color}`};
  ${({ position }) => position && `position : ${position}`};
  ${({ border }) => border && `border : ${border}`};
  ${({ borderRight }) => borderRight && `border-right : ${borderRight}`};
  ${({ borderRadius }) => borderRadius && `border-radius : ${borderRadius}`};
  ${({ top }) => top && `top : ${top}`};
  ${({ left }) => left && `left : ${left}`};
  ${({ bottom }) => bottom && `bottom : ${bottom}`};
  ${({ right }) => right && `right : ${right}`};
  ${({ animation }) => animation && `animation : ${animation}`};
  ${({ minHeight }) => minHeight && `min-height : ${minHeight}`};
  ${({ alignContent }) => alignContent && `align-content : ${alignContent}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content : ${justifyContent}`};
  ${({ flexDirection }) =>
    flexDirection && `flex-direction : ${flexDirection}`};
  ${({ flexWrap }) => flexWrap && `flex-wrap : ${flexWrap}`};
  ${({ flexShrink }) => flexShrink && `flex-shrink : ${flexShrink}`};
  ${({ alignItems }) => alignItems && `align-items : ${alignItems}`};
  ${({ fontSize }) => fontSize && `font-size : ${fontSize}`};
  ${({ fontWeight }) => fontWeight && `font-weight : ${fontWeight}`};
  ${({ borderRadius }) => borderRadius && `border-radius : ${borderRadius}`};
  ${({ zIndex }) => zIndex && `z-index : ${zIndex}`};
  ${({ letterSpacing }) =>
    letterSpacing && `letter-spacing : ${letterSpacing}`};
  ${({ lineHeight }) => lineHeight && `line-height : ${lineHeight}`};
  ${({ boxShadow }) => boxShadow && `box-shadow : ${boxShadow}`};
  ${({ textAlign }) => textAlign && `text-align : ${textAlign}`};
  ${({ fontSize }) => fontSize && `font-size : ${fontSize}`};
  ${({ fontWeight }) => fontWeight && `font-weight : ${fontWeight}`};

  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-30px);
    }
  }

  @media screen and (max-width: 900px) {
    ${({ mobile }) => ({ ...mobile })};
  }
`;

export default Div;
