import LogoGray from '@/assets/logo_gray.svg';
import TextSolutionForYou from '@/assets/text/solution_for_you.svg';
import Layout from '@/components/Layout';
import View from '@/components/View';
import { COLORS } from '@/configs/color';

const Section02 = () => (
  <Layout.Div
    height="auto"
    background="#FAFAFA"
    boxShadow="inset 0 10px 30px rgba(0,0,0,0.04)"
  >
    <Layout.Wrap padding="100px 0" position="relative">
      {/* 내용 시작 */}
      <Layout.Div
        background={`url(${LogoGray}) left / contain no-repeat`}
        width="100%"
        height="24px"
        mobile={{
          height: '18px',
        }}
      />
      <Layout.Div
        background={`url(${TextSolutionForYou}) left / contain no-repeat`}
        width="100%"
        height="131px"
        margin="20px 0 0"
        mobile={{
          margin: '12px 0 0',
          'max-height': '60px',
          height: '60px',
        }}
      />
      <Layout.Div
        textAlign="left"
        margin="40px 0 100px"
        fontSize="1.1rem"
        fontWeight="500"
        color="#777"
      >
        30일 연구소는 고객님의 비지니스에 시원한 솔루션을 제공합니다.
      </Layout.Div>
      {/* 스타트업(중,소 비지니스)가 직면한 어려운 과제 */}
      <Layout.Div
        width="100%"
        mobile={{ width: '100%' }}
        margin="0 0 96px"
        position="relative"
        zIndex="2"
      >
        <Layout.Div
          fontSize="1.8rem"
          fontWeight="700"
          color={COLORS.DARK_BLUE}
          textAlign="center"
        >
          스타트업(중,소 비지니스)가 직면한 어려운 과제
        </Layout.Div>
        <Layout.Div
          display="flex"
          width="100%"
          margin="40px 0 0"
          height="450px"
          flexDirection="column"
          flexWrap="wrap"
          alignContent="space-between"
          mobile={{
            height: 'auto',
          }}
        >
          <View.ColorBox
            title="Reach & Retention"
            body="iOS와 Android 네이티브 앱을 각자 개발하고 배포하는 과정은 많은 자원과 시간이 들어갑니다. 이러한 문제 때문에 모바일 환경에서 사용자 친화적이지 못한 웹앱을 선택하는 경우가 많습니다. "
            background={COLORS.DARK_BLUE}
            color="white"
          />
          <View.ColorBox
            title="비지니스 검증을 위한 Lean Startup"
            body="위의 첫번째에서 말씀드린 개발&배포의 시간이 길면 비지니스의 검증 또는 사용자들의 피드백을 개선하는 일이 신속하게 되지 않습니다. 이러면 실제 사용자들의 요구를 반영되지 못한 제품 또는 서비스가 될 가능성이 있습니다."
            background={COLORS.DARK_BLUE}
            color="white"
          />
          <View.ColorBox
            title="High quality look / feel"
            body="비용절감 및 효율성을 위해서 하이브리드(웹) 앱을 제작하면 앱 사용성에 대한 퀄리티가 많이 떨어지고, 기대한 만큼의 디자인의 구현이 쉽지 않습니다. 이러한 부분은 바로 사용자들에게 좋지못한 첫인상을 주고, 결국 비지니스에도 큰 문제점으로 남게됩니다."
            background={COLORS.DARK_BLUE}
            color="white"
          />
          <View.ColorBox
            title="Affordability"
            body="퀄리티 있는 모바일앱의 개발 비용은 대부분의 비지니스가 직면한 문제입니다. iOS, 안드로이드, 서버 개발자 그리고 디자이너까지 고용하면서 개발을 하려면 많은 비용이 발생되기 때문에, 사실 쉽게 앱을 제작하겠다는 생각을 하기는 쉽지 않습니다. "
            background={COLORS.DARK_BLUE}
            color="white"
          />
        </Layout.Div>
      </Layout.Div>
      {/* 30일 연구소의 솔루션 */}
      <Layout.Div
        width="100%"
        mobile={{ width: '100%' }}
        margin="0 0 96px"
        position="relative"
        zIndex="2"
      >
        <Layout.Div
          fontSize="1.8rem"
          fontWeight="700"
          color={COLORS.MINT}
          textAlign="center"
        >
          30일 연구소의 솔루션
        </Layout.Div>
        <Layout.Div
          display="flex"
          width="100%"
          margin="40px 0 0"
          height="700px"
          flexDirection="column"
          flexWrap="wrap"
          alignContent="space-between"
          mobile={{
            height: 'auto',
          }}
        >
          <View.ColorBox
            title="하나의 코드로 iOS와 안드로이드 네이티브 앱"
            body="30일 연구소는 Google에서 만들고 지원하는 Flutter 프레임워크를 사용해서 iOS와 안드로이드 네이티브 앱을 제작합니다. 이미 전세계에서 많은 비지니스들이 이 프레임워크를 사용해 개발하고 수많은 사람들이 사용중입니다. "
            background={COLORS.MINT}
            color="white"
          />
          <View.ColorBox
            title="신속한 개발 속도"
            body="스타트업 또는 중, 소 비지니스의 상황상 빠르게 개발 및 배포해야하기 때문에 이 신속한 스피드를 만들 수 있는 기술을 사용합니다. 단순히 빠른 개발뿐만이 아니라 퀄리티와 안정성이 동반한 개발을 진행합니다. "
            background={COLORS.MINT}
            color="white"
          />
          <View.ColorBox
            title="Analytics"
            body="30일 연구소는 앱을 만들어내는 단순한 개발사가 아닙니다. 고객님의 비지니스를 돕기위한 서포트를 하는 연구소이며, 사용자들이 앱 안에서 어떤 행동을 보이는지 무엇을 원하는지 파악을 하는데 초점을 맞춥니다. 지피지기100전 100승이라는 말이 있듯이, 이러한 분석은 고객님의 비지니스의 사용자들을 더 잘 알게 해줍니다. 이러한 분석을 바탕으로 좀 더 구체적이고 명확한 방향성을 잡을 수 있고, 성장하고 성공적인 결과를 만들 수 있습니다. "
            background={COLORS.MINT}
            color="white"
          />
          <View.ColorBox
            title="사용자 친화적인 모바일 앱"
            body="30일 연구소는 사용자 친화적인 개발을 하는데 초점을 맞추고 있습니다. 그 사람의 얼굴은 그 사람의 많은 것을 보여주듯이, 앱의 퀄리티와 사용자 친화적인 UI / UX가 사용자들에게 쾌적한 느낌과 경험을 줄수 있도록 개발합니다."
            background={COLORS.MINT}
            color="white"
          />
          <View.ColorBox
            title="합리적이고 저렴한 비용"
            body="30일 연구소는 Lean Startup 방법을 고수하고, 우리의 고객님도 이 방법론을 고수하기를 기대합니다. 이런한 방법론을 바탕으로 필요한 기능을 비지니스의 단계에 따라서 개발하고 비용을 지불하기 때문에 합리적이고, 비용이 분산되기 때문에 고객님의 비지니스에도 덜 부담이 됩니다. 비지니스의 핵심 기능이 담긴 첫번째 버전의 앱의 개발 비용은 500만원 ~ 1000만원으로 컨설팅을 과정을 통해서 책정됩니다. "
            background={COLORS.MINT}
            color="white"
          />
        </Layout.Div>
      </Layout.Div>
      {/* 내용 끝 */}
    </Layout.Wrap>
  </Layout.Div>
);

export default Section02;
