import Layout from '@/components/Layout';
import Sections from '@/containers/Sections';

const App = () => (
  <Layout.Div className="App">
    <Sections.Section01 />
    <Sections.Section02 />
    <Sections.Section03 />
    <Sections.Section04 />
    <Sections.Section05 />
    <Sections.Section06 />
    <Sections.Section07 />
    <Sections.Section08 />
    <Sections.Bottom />
  </Layout.Div>
);

export default App;
